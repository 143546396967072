<template>
  <div>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab title="Reviews" active>
          <p style="margin-top:-20px">
            <ReviewList />
          </p>
        </b-tab>
        <b-tab title="Internal Feedback">
          <div style="margin-top:-20px">
            <div v-if="!loadingFeedback">
              <div v-if="datatable.items.length">
                <div class="table-responsive mb-0">
                <b-table :items="datatable.items" :fields="datatable.columns">
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(feedback)="data">
                    {{data.item.feedback}}
                  </template>
                </b-table>
              </div>
                <div class="row mt-4">
                <div class="col">
                  <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          v-model="datatable.queries.page"
                          :total-rows="datatable.totalItems"
                          :per-page="datatable.queries.per_page"
                          @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              </div>
              <div v-else class="mt-5">
                <h3 class="text-center">You do not currently have any feedback</h3>
              </div>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>

  </div>
</template>

<script>
import ReviewList from '@components/Common/ReviewsList.vue';
import Vue from "vue";
export default {
  components:{
    ReviewList,
  },
  data(){
    return {
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          {key: 'name', thStyle: { width: '150px' }},
          {key: 'email'},
          {key: 'feedback'},
          {key: 'front_date', label:'Date'},
        ],
        queries: {
          per_page: 10,
          page: 1,
        },
      },
      loadingFeedback:false,
      user:null
    }
  },
  computed:{
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    this.getFeedback();
  },
  methods:{
    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getFeedback()
    },
    getFeedback() {
      this.loadingFeedback = true
      this.$store
          .dispatch('reviews/getAllFeedback', {
            queries:this.datatable.queries,
            page:'index',
            businessId:this.user.business.id
          })
          .then((res) => {
            console.log(res);
            this.datatable.items = res.data;
            this.datatable.totalItems = res.total;
            this.loadingFeedback = false
          })
          .catch(() => {
            this.loadingFeedback = false
          })
    },
  }
}
</script>
